import React from 'react';
import { LogoLoader } from 'components/logoLoader';
import { HeaderLandingPage } from 'components/headerLandingPage';



const Home: React.FC = () => {

  return (
    <div className="Home">
      <HeaderLandingPage />
      <LogoLoader />
    </div>
  );
}

export default Home;
