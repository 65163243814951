import React, { useState, useEffect } from 'react'
import posed from 'react-pose';

import logo from 'assets/images/logo.png'
import dot from 'assets/images/dot.png'

const LogoBox = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
})
const LogoImg = posed.img()
const LogoDot1 = posed.img({
    open: { x: '80%' },
    closed: { x: '-50%' }

})
const LogoDot2 = posed.img({
    open: { x: '100%' },
    closed: { x: '-80%' }


})

export const LogoLoader: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [isMove, setIsMove] = useState(false)

    useEffect(() => {
        setIsVisible(true)
        const timer =
            setTimeout(() => {
                setIsMove(!isMove)
            }, 500);
        return () => {
            clearTimeout(timer)

        };
    }, [isMove])
    return (
        <LogoBox className="Logo-box" pose={isVisible ? 'visible' : 'hidden'} >
            <LogoDot1 src={dot} alt="Dot" className="Logo-dot" pose={isMove ? 'open' : 'closed'} />
            <LogoDot2 src={dot} alt="Dot" className="Logo-dot" pose={isMove ? 'open' : 'closed'} />
            <LogoImg src={logo} alt="Logo" className="Logo-img" />
        </LogoBox>
    )
}
