import React from 'react'


export const HeaderLandingPage: React.FC = () => {
    return (
        <React.Fragment>
            <span className="Header-text">Will soon start...</span>
        </React.Fragment>
    )
}


